<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <div >
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="field.data_table_field_index && field.data_table_field_index > -1 ? 8 : 12">
          <div class="d-flex">
            <el-form-item label="Min select" v-if="field.allow_multiple">
              <el-input-number
                v-model="field.min_value"
                :max="field.max_value"
                controls-position="right"
                :min="0"
                :readonly="true"
                :step="1"
              >
              </el-input-number>
            </el-form-item>
            <el-form-item
              label="Max select"
              v-if="field.allow_multiple"
              class="ml-1"
            >
              <el-input-number
                v-model="field.max_value"
                :max="7"
                :min="field.min_value"
                controls-position="right"
                :readonly="true"
                :step="1"
              >
              </el-input-number>
            </el-form-item>
          </div>
          <el-checkbox v-model="field.allow_multiple">
            Allow multiple
          </el-checkbox>
        </el-col>
        <el-col :span="6" v-if="!field.allow_multiple && field.data_table_field_index && field.data_table_field_index > -1">
          <el-checkbox v-model="field.data_table_field_auto_fill" @change="disableField">
            Auto Fill
          </el-checkbox>
          <el-tooltip content="Selected Date or Date-time field day will be automatically filled" placement="top-start">
              <i class="el-icon-info" style="margin-left : 5px;"></i>
            </el-tooltip>
          <br><br>
          <el-select v-model="field.selected_auto_fill_fields.from" v-if="field.data_table_field_auto_fill" placeholder="Select Date">
            <el-option-group label="Date">
              <el-option v-for="(date, index) in dataTableDateFields" :key="index" :value="date.key" :label="date.label"></el-option>
            </el-option-group>
            <el-option-group label="Weekdays">
              <el-option v-for="(date, index) in dataTableWeekDaysMultipleFields" :key="index" :value="date.key" :label="date.label"></el-option>
            </el-option-group>
          </el-select>
        </el-col>
        <el-col :span="field.data_table_field_index && field.data_table_field_index > -1 ? 10 : 12">
          <is-field-required :field="field" class="field-required" />
        </el-col>
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" class="field-required" />
    </el-form>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["field","fieldsData"],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    // FieldAttributes: () => import("./FieldAttributes"),
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      console.log("getAllGlobalVariables",this.getAllGlobalVariables);
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    }
  },
  mounted() {
    this.fetchGlobalVaribales();
    this.fetchFieldsForAutoFill()
  },
  data() {
    return {
      dataTableDateFields : [],
      dataTableWeekDaysMultipleFields : []
    };
  },
  methods: {
    async fetchGlobalVaribales() {
     
      let params = {
        get_all: true,
        input_type: this.field.input_type
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
    fetchFieldsForAutoFill(){
      this.dataTableDateFields = this.fieldsData.find(e=> this.fieldsData.indexOf(e) == this.field.data_table_field_index).data_table_columns.filter(e=> e.input_type == 'DATE' || e.input_type == 'DATE_TIME')
      this.dataTableWeekDaysMultipleFields = this.fieldsData.filter(e=> e.input_type == 'WEEKDAYS' && e.allow_multiple == true && e.data_table_key == undefined)
    },
    disableField(){
      console.log("filed_content",this.field.properties['filed_content'])
      if(this.field.data_table_field_auto_fill == true){
        this.field.properties['filed_content'] = 'DISABLED'
      }
      else{
        this.field.properties['filed_content'] = ''
      }
    }
  }
};
</script>

<style></style>
